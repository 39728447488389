import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import PortfolioTeaser from '../portfolio-teaser/portfolioTeaser';

const Kitchen = () => {
  const data = useStaticQuery(graphql`
    {
      kitchenImage: file(name: { eq: "kitchen-bg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
      teaserImages: allFile(
        filter: { relativeDirectory: { eq: "teasers/kitchen-teaser" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <div className='mx-auto flex max-w-screen-xl flex-col items-center px-4'>
        <GatsbyImage
          image={data.kitchenImage.childImageSharp.gatsbyImageData}
          className='-mt-8'
          alt=''
        />
        <p className='w-full text-lg xl:w-3/5'>
          <span className='block font-bold'>Kuchnie, meble kuchenne</span>W
          mojej ofercie znajdziesz zabudowy kuchni, oraz meble kuchenne.
          Priorytetem dla mnie jest to aby zabudowa była funkcjonalna i
          estetyczna a meble kuchenne spełniały swoją rolę.
          <br /> Szeroki wybór materiałów oraz kolorów. Wszystkie części ruchome
          mebli mogą być zrealizowane na systemach firmy Blum, która jest
          liderem tego typu rozwiązań. Oczywiście nic nie stoi na przeszkodzie
          aby korzystać z rozwiązań innych firm.
        </p>
      </div>
      <PortfolioTeaser
        title='Wybrane realizacje zabudów i mebli kuchennych'
        imageData={data.teaserImages.edges}
        link='/realizacje/kuchnie'
        textLink='Zobacz więcej'
      />
    </>
  );
};

export default Kitchen;
