import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MyCarousel from '../carousel/myCarousel';
import Header from '../headers/headers';

const Partners = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "partners" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <div className='mx-auto flex max-w-screen-xl flex-col items-center justify-center p-4'>
      <Header size='h2' className='text-myOrange'>
        Używam komponentów firm:
      </Header>
      <div className='mx-auto flex w-full max-w-screen-xl flex-col px-4'>
        <MyCarousel items={data.allFile.edges} />

        {/* {data.allFile.edges.map(({ node }) => {
          return (
            <GatsbyImage
              key={node.id}
              image={node.childImageSharp.gatsbyImageData}
            />
          );
        })} */}
      </div>
    </div>
  );
};

export default Partners;
