import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import PortfolioTeaser from '../portfolio-teaser/portfolioTeaser';
import Header from '../headers/headers';

const Cabinets = () => {
  const data = useStaticQuery(graphql`
    {
      cabinetImage: file(name: { eq: "cabinets" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      freeCabinetImage: file(name: { eq: "free-cabinets" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      wardroomImage: file(name: { eq: "wardroom" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      teaserImages: allFile(
        filter: { relativeDirectory: { eq: "teasers/cabinets-teaser" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <div className='flex flex-col'>
      <div className='mx-auto flex max-w-screen-xl flex-col gap-8 px-4 md:flex-row'>
        <div className='flex w-full flex-col justify-center'>
          <Header size='h3' className='text-myOrange lg:text-5xl'>
            Szafy wnękowe
          </Header>
          <p>
            <span className='text-lg font-bold'>Szafy wnękowe</span> z drzwiami
            suwanymi na systemach polskiego producenta Sevroll, sprawdzą się
            wszędzie tam, gdzie kluczowe jest optymalne wykorzystanie
            powierzchni mieszkalnej oraz zwiększenie jej funkcjonalności.
          </p>
        </div>
        <div className='flex w-full justify-center bg-oval-bg bg-contain bg-center bg-no-repeat'>
          <GatsbyImage
            image={data.cabinetImage.childImageSharp.gatsbyImageData}
            alt=''
          />
        </div>
      </div>
      <div className='mx-auto flex max-w-screen-xl flex-col gap-8 px-4 md:flex-row-reverse'>
        <div className='flex w-full flex-col justify-center'>
          <Header size='h3' className='mt-8 text-myOrange lg:text-5xl'>
            Szafy wolnostojące
          </Header>
          <p>
            <span className='text-lg font-bold'>Szafy wolnostojące</span> to
            funkcjonalne meble, które w przypadku zmiany wystroju można
            przestawić w inne miejsce. Mnogość kolorów i wykończeń sprawia, że
            da się je dopasować do nawet już urządzonych pomieszczeń.
          </p>
        </div>
        <div className='flex w-full justify-center bg-oval-bg bg-contain bg-center bg-no-repeat p-4'>
          <GatsbyImage
            image={data.freeCabinetImage.childImageSharp.gatsbyImageData}
            alt=''
          />
        </div>
      </div>
      <div className='mx-auto flex max-w-screen-xl flex-col gap-8 px-4 md:flex-row'>
        <div className='flex w-full flex-col justify-center'>
          <Header size='h3' className='mt-8 text-myOrange lg:text-5xl'>
            Garderoby na wymiar
          </Header>
          <p>
            <span className='text-lg font-bold'>Garderoba na wymiar</span> to
            wygodne rozwiązanie, które sprawdzi się w każdym domu. Pomieszczenie
            na garderobę można ciekawie zaprojektować oszczędzając w ten sposób
            na przestrzeniach w innych częściach domu. Jako garderobę możemy
            potraktować osobne pomieszczenie lub specjalnie wykonaną szafę. W
            jednym i drugim przypadku przy wykonaniu liczy się pomysłowość,
            doświadczenie i funkcjonalność.
          </p>
        </div>
        <div className='flex w-full justify-center bg-contain bg-center bg-no-repeat'>
          <GatsbyImage
            image={data.wardroomImage.childImageSharp.gatsbyImageData}
            alt=''
          />
        </div>
      </div>
      <PortfolioTeaser
        title='Wybrane realizacje szaf wnękowych, wolnostojących i garderób'
        imageData={data.teaserImages.edges}
        link='/realizacje/szafy'
        textLink='Zobacz więcej'
      />
    </div>
  );
};

export default Cabinets;
