import { Link } from 'gatsby';
import React from 'react';
import Header from '../headers/headers';

const Outro = () => {
  return (
    <div className='mx-auto mb-16 flex max-w-screen-xl flex-col items-center gap-4 px-4'>
      <Header size='h2' className='text-myOrange'>
        Co mogę zrobić dla Ciebie?
      </Header>
      <p className='max-w-[750px] text-lg'>
        Jeżeli jesteś zainteresowany/a wykonaniem estetycznych i funkcjonalnych
        mebli na wymiar{' '}
        <span className='font-bold text-myOrange'>
          <Link to='/kontakt'>skontaktuj</Link>
        </span>{' '}
        się ze mną telefonicznie lub mailowo.
        <br /> Możesz też poczytać jak{' '}
        <span className='font-bold text-myOrange'>
          <Link to='/jak-dzialam'>działam</Link>
        </span>{' '}
        lub przejrzeć moje wybrane{' '}
        <span className='font-bold text-myOrange'>
          <Link to='/realizacje'>realizacje</Link>
        </span>
        .
      </p>
    </div>
  );
};

export default Outro;
