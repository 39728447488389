import React from 'react';
import { graphql } from 'gatsby';
import Divider from '../components/divider/divider';
import Cabinets from '../components/Furnitures/cabinets';
import Kitchen from '../components/Furnitures/kitchen';
import Others from '../components/Furnitures/others';
import Hello from '../components/hello/hello';
import Intro from '../components/intro/Intro';
import Outro from '../components/outro/outro';
import Partners from '../components/partners/partners';
import Header from '../components/headers/headers';

import IndexLayout from './../layouts/index';

const IndexPage = ({ data }) => {
  return (
    <IndexLayout noScroll={false}>
      <main>
        <Intro image={data.intro} />
        <Divider />
        <Hello />
        <div className='my-12 bg-myOrange'>
          <Header size='h2' className='text-white'>
            Szafy wnękowe, wolnostojące, garderoby.
          </Header>
        </div>
        <Cabinets />
        <div className='my-12 bg-myOrange'>
          <Header size='h2' className='text-white'>
            Kuchnie, meble kuchenne
          </Header>
        </div>
        <Kitchen />
        <Others />
        <Partners />
        {/* <Clients /> */}
        <Outro />
      </main>
    </IndexLayout>
  );
};

export const query = graphql`
  {
    intro: file(name: { eq: "intro" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default IndexPage;

export function Head() {
  return (
    <>
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta
        name='description'
        content='Meble biurowe, kuchenne, szafy, zabudowy. Wszystko na wymiar. Koszalin i okolice. Ponad 25 lat doświadczenia'
      />
      <meta name='robots' content='index,follow' />
      <link href='https://stromich.eu/' rel='canonical'></link>
      <title>Stromich - meble na wymiar</title>
    </>
  );
}
