import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowRightIcon } from '@heroicons/react/outline';

const PortfolioTeaser = ({ title, imageData, link, textLink }) => {
  return (
    <div className='mx-auto mt-4 flex max-w-screen-xl flex-col items-center p-4'>
      <p className='text-center text-2xl font-bold'>{title}</p>
      <div className='flex flex-col gap-4 py-8 sm:flex-row'>
        {imageData.map(({ node }) => {
          return (
            <GatsbyImage
              image={node.childImageSharp.gatsbyImageData}
              className='w-full shadow-xl'
              key={node.id}
              alt=''
            />
          );
        })}
      </div>
      <Link
        to={link}
        className='group flex items-center gap-2 border-1 border-myOrange p-4 transition-colors hover:bg-myOrange'
      >
        <span className='text-lg font-semibold text-myOrange transition-colors group-hover:text-white'>
          {textLink}
        </span>
        <ArrowRightIcon className='h-6 w-6 text-myOrange transition-colors group-hover:text-white' />
      </Link>
    </div>
  );
};

export default PortfolioTeaser;
