import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import PortfolioTeaser from '../portfolio-teaser/portfolioTeaser';
import Header from '../headers/headers';

const Others = () => {
  const data = useStaticQuery(graphql`
    {
      otherImage: file(name: { eq: "others" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      teaserImages: allFile(
        filter: { relativeDirectory: { eq: "teasers/other" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className='mx-auto flex max-w-screen-xl flex-col gap-8 px-4 md:flex-row-reverse'>
        <div className='flex w-full flex-col justify-center'>
          <Header size='h3' className='mt-8 text-myOrange lg:text-5xl'>
            Inne meble
          </Header>
          <p>
            Zajmuje się również realizacją mebli do salonu, pod sprzęt TV, mebli
            biurowych, mebli do ośrodków wypoczynkowych oraz apartamentów.
          </p>
        </div>
        <div className='flex w-full justify-center bg-oval-bg bg-contain bg-center bg-no-repeat py-10'>
          <GatsbyImage
            image={data.otherImage.childImageSharp.gatsbyImageData}
            className=''
            alt=''
          />
        </div>
      </div>
      <PortfolioTeaser
        title='Wybrane realizacje mebli'
        imageData={data.teaserImages.edges}
        link='/realizacje/meble'
        textLink='Zobacz więcej'
      />
    </>
  );
};

export default Others;
