import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ArrowRightIcon } from '@heroicons/react/outline';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1200, min: 640 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 2,
  },
};

const MyCarousel = ({ items }) => {
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <ArrowRightIcon
        className='absolute right-2 h-8 w-8 cursor-pointer text-slate-500 transition-colors hover:text-orange-500 '
        onClick={() => onClick()}
      />
    );
  };
  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <ArrowRightIcon
        className='absolute left-2 h-8 w-8 rotate-180 cursor-pointer text-slate-500 transition-colors hover:text-orange-500 '
        onClick={() => onClick()}
      />
    );
  };

  return (
    <Carousel
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      responsive={responsive}
      removeArrowOnDeviceType={['mobile']}
      showDots={false}
      autoPlay={true}
      className='flex'
    >
      {items.map(({ node }) => {
        return (
          <div className='flex h-20 items-center justify-center' key={node.id}>
            <GatsbyImage image={node.childImageSharp.gatsbyImageData} />
          </div>
        );
      })}
    </Carousel>
  );
};

export default MyCarousel;
