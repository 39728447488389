import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hello = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "hello" }) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const checkHour = () => {
    const thisHour = new Date();
    return thisHour.getHours();
  };

  return (
    <div className='mx-auto my-8 grid w-full max-w-screen-xl grid-cols-1 items-center justify-center gap-4 lg:my-16 lg:grid-cols-2'>
      <div className='px-4'>
        <p className='mb-8 text-2xl font-bold text-myOrange lg:text-4xl'>
          {checkHour() > 19 ? 'Dobry wieczór' : 'Dzień dobry'}
        </p>
        <p className='text-base lg:text-xl lg:leading-9 '>
          Nazywam się Sebastian Stromich i zawodowo zajmuje się meblarstwem.
          <br />
          Wykonuje kompleksowe realizacje mebli z projektu klienta, ale również
          tworzę projekty uwzględniające potrzeby i oczekiwania klientów.
          <br />
          Oferuję kompleksową obsługę. Od projektu, przez doradztwo po
          wykonanie.
          <br />
          <br />
          Istnieje możliwość współpracy z projektantami wnętrz, którzy
          zwizualizują koncepcję mebli czy zabudowy uwzględniając państwa
          oczekiwania.
          <br />
          <br />W głównej mierze wykonuję realizacje w Koszalinie i okolicach
          (Mielno, Unieście, Kołobrzeg). Ale możliwe są realizacje na terenie
          Polski.
        </p>
      </div>
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        className='h-half lg:h-full lg:rounded-tr-2xl lg:rounded-br-2xl'
        alt=''
      />
    </div>
  );
};

export default Hello;
